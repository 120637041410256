<template>
  <component :is="layout">
    <div v-if="dailyGame" class="card shadow p-2 m-2 text-center">
      <h3 class="font-weight-bold text-dark py-2">{{ dailyGame.name }}</h3>
      <span v-if="message">
        <p class="text-center alert-success">{{ message }}</p>
      </span>
      <div class="form-group">
        <label for="result">Result</label>
        <div class="d-flex justify-content-center">
          <div>
            <label for="">Open</label> <br />
            <input
              @input="handleJodi('delete')"
              type="text"
              size="3"
              maxlength="3"
              v-model="patti_open"
            />-
          </div>
          <div>
            <label for="">Jodi</label><br />
            <input
              type="text"
              size="2"
              maxlength="2"
              readonly
              v-model="jodi"
            />-
          </div>

          <div>
            <label for="">Close</label><br />
            <input
              @input="handleJodi()"
              type="text"
              size="3"
              maxlength="3"
              v-model="patti_close"
            />
          </div>
        </div>
      </div>
      <button
        @click="daily_game_update()"
        class="btn my-2 btn-block btn-success"
      >
        Update
      </button>
    </div>

    <div class="row m-3 p-2 m-1 justify-content-around">
      <div
        class="col-md-5 text-center py-5 p-2 m-1 shadow card bg-primary text-white"
      >
        <router-link class="text-white" :to="{ name: 'client_daily_game' }"
          >Daily Games</router-link
        >
      </div>
      <div
        class="col-md-5 text-center py-5 p-2 m-1 shadow card bg-primary text-white"
      >
        <router-link class="text-white" :to="{ name: 'client_table_game' }"
          >Table Games</router-link
        >
      </div>
    </div>

    <div class="py-3" v-for="table_time in table_times" :key="table_time.id">
      <div v-if="table_time" class="m-3 p-2 card">
        <div class="card-header">
          <h3>{{ table_time.game_name }}</h3>
        </div>
        <div v-if="table_time.table_game" class="card-body">
          <div class="row justify-content-around">
            <input
              v-if="table_time.one"
              placeholder="game 1"
              class="col-6 p-1"
              size="10"
              v-model="table_time.table_game.one"
              type="text"
            />
            <input
              v-if="table_time.two"
              placeholder="game 2"
              class="col-6 p-1"
              size="10"
              v-model="table_time.table_game.two"
              type="text"
            />
            <input
              v-if="table_time.three"
              placeholder="game 3"
              class="col-6 p-1"
              size="10"
              v-model="table_time.table_game.three"
              type="text"
            />
            <input
              v-if="table_time.four"
              placeholder="game 4"
              class="col-6 p-1"
              size="10"
              v-model="table_time.table_game.four"
              type="text"
            />
            <input
              v-if="table_time.five"
              placeholder="game 5"
              class="col-6 p-1"
              size="10"
              v-model="table_time.table_game.five"
              type="text"
            />
            <input
              v-if="table_time.six"
              placeholder="game 6"
              class="col-6 p-1"
              size="10"
              v-model="table_time.table_game.six"
              type="text"
            />
            <input
              v-if="table_time.seven"
              placeholder="game 7"
              class="col-6 p-1"
              size="10"
              v-model="table_time.table_game.seven"
              type="text"
            />
            <input
              v-if="table_time.eight"
              placeholder="game 8"
              class="col-6 p-1"
              size="10"
              v-model="table_time.table_game.eight"
              type="text"
            />
            <input
              v-if="table_time.nine"
              placeholder="game 9"
              class="col-6 p-1"
              size="10"
              v-model="table_time.table_game.nine"
              type="text"
            />
            <input
              v-if="table_time.ten"
              placeholder="game 10"
              class="col-6 p-1"
              size="10"
              v-model="table_time.table_game.ten"
              type="text"
            />
            <input
              v-if="table_time.eleven"
              placeholder="game 11"
              class="col-6 p-1"
              size="10"
              v-model="table_time.table_game.eleven"
              type="text"
            />
            <input
              v-if="table_time.twelve"
              placeholder="game 12"
              class="col-6 p-1"
              size="10"
              v-model="table_time.table_game.twelve"
              type="text"
            />
            <input
              v-if="table_time.thirteen"
              placeholder="game 13"
              class="col-6 p-1"
              size="10"
              v-model="table_time.table_game.thirteen"
              type="text"
            />
            <input
              v-if="table_time.fourteen"
              placeholder="game 14"
              class="col-6 p-1"
              size="10"
              v-model="table_time.table_game.fourteen"
              type="text"
            />
          </div>
        </div>
        <div v-if="table_time.table_game" class="text-center">
          <button
            @click="update_table_game(table_time.id)"
            class="w-100 btn my-2 btn-block btn-success"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import axiosObject from "../../axiosHandler";
import axiosConfig from "../../axiosConfig";
export default {
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
  data() {
    return {
      dailyGame: null,
      result: null,
      message: null,
      patti_open: null,
      jodi: null,
      patti_close: null,
      table_times: null,
    };
  },
  mounted() {
    this.fetchDailyGame();
    this.fetchLatestTableRecords();
  },
  methods: {
    fetchDailyGame() {
      axiosObject
        .get("/api/client/daily_game", axiosConfig)
        .then(
          function (response) {
            this.dailyGame = response.data.daily_games;
            this.result = response.data.daily_games.result;
            if (this.result.length == 5) {
              this.patti_open = this.result.substring(0, 3);
              this.jodi = this.result.substring(4, 6);
            } else if (this.result.length > 5) {
              this.patti_open = this.result.substring(0, 3);
              this.jodi = this.result.substring(4, 6);
              this.patti_close = this.result.substring(7, 10);
            }
          }.bind(this)
        )
        .catch(function (error) {
          alert(error.response.data.message);
          if (error.response.status == 401) {
            localStorage.clear();
            window.location.reload();
          }
        });
    },
    daily_game_update() {
      this.result = this.patti_open + "-" + this.jodi + "-" + this.patti_close;
      this.result = this.result.replace(/\-$/, "");
      axiosObject
        .post(
          "/api/client/daily_game_dashboard_update",
          {
            result: this.result,
          },
          axiosConfig
        )
        .then(
          function (response) {
            this.message = response.data.message;
          }.bind(this)
        )
        .catch(function (error) {
          alert(error.response.data.message);
          if (error.response.status == 401) {
            localStorage.clear();
            window.location.reload();
          }
        });
    },
    fetchLatestTableRecords() {
      axiosObject
        .get("/api/client/table_latest_records", axiosConfig)
        .then(
          function (response) {
            this.table_times = response.data.tableTimes;
            console.log(this.table_times);
          }.bind(this)
        )
        .catch(function (error) {
          alert(error.response.data.message);
          if (error.response.status == 401) {
            localStorage.clear();
            window.location.reload();
          }
        });
    },

    update_table_game(table_time_id) {
      var table_time = this.table_times.find(
        (element) => element.id === table_time_id
      );
      var table_game = table_time.table_game;
      axiosObject
        .patch(
          "/api/client/table_game/" + table_game.id,
          table_game,
          axiosConfig
        )
        .then(
          function (response) {
            this.message = "success";
          }.bind(this)
        )
        .catch(function (error) {
          alert(error.response.data.message);
          if (error.response.status == 401) {
            localStorage.clear();
            window.location.reload();
          }
        });
    },
    handleJodi(canDelete= null) {
      if (canDelete =="delete") {
        this.patti_close = ''
      }
      let JodiAnk1 = this.getJodiAnk(this.patti_open)
      let jodiAnk2 = this.getJodiAnk(this.patti_close)
      this.jodi = JodiAnk1.toString() + jodiAnk2.toString()
    },
    getJodiAnk(patti){
      if (patti == "" || patti ==null) {
        return ''
      }
      if (patti.includes("*")) {
        return '*'
      }
      let sum = 0;
      for (let i = 0; i < patti.length; i++) {
        sum += parseInt(patti[i]);
      }
      return sum % 10;
    }
  },
};
</script>

<style>
</style>
